import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  register (query, data) {
    let route = '';
    if (query) {
      route = 'v1/register?' + query;
    } else {
      route = 'v1/register';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  register_mail_approve (query, data) {
    let route = '';
    if (query) {
      route = 'v1/register/mail/approve?' + query;
    } else {
      route = 'v1/register/mail/approve';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
};
